
export default class Gallery{
	constructor(){
	}
	initialize(dom){
		let targetDom = document;
		if(dom){
			if(dom instanceof Element){
				targetDom = dom;
			}
		}
		if(targetDom.querySelectorAll('.gallery').length){
			this.galleries = Array();
			this.galleries.push(new GalleryBlock(targetDom.querySelector('#gallery_works'), "normal"));
			// this.galleries.push(new GalleryBlock(targetDom.querySelector('#gallery_collection'), "collection"));
		}
	}
}

class GalleryBlock{
	constructor(gallery, type){
		this.gallery = gallery;
		this.type = type;
		this.closeButton = this.gallery.querySelector('.gallery__close');
		this.container = this.gallery.querySelector('.gallery__container');
		this.prevButton = this.gallery.querySelector('.gallery__button__prev');
		this.nextButton = this.gallery.querySelector('.gallery__button__next');
		this.pagerCurrent = this.gallery.querySelector('.gallery__pager__current');
		this.callerButton = document.querySelector('a[href="#'+(this.gallery.getAttribute("id"))+'"]');
		this.maxPage = this.gallery.getAttribute("data-max")*1;
		this.currentPage = 0;
		this.itemImages = Array();
		this.openAnimDelay = null;
		this.isOpen = false;

		this.gallery.querySelectorAll('.gallery__item__block').forEach((item) => {
			this.itemImages.push({
				block: item,
				imgContainer: item.querySelector('.gallery__item__image'),
				img: item.querySelector('img'),
				width: item.querySelector('img').getAttribute('width'),
				height: item.querySelector('img').getAttribute('height'),
			})
		});

		this.nextButton.addEventListener("click", (e)=>{ 
			this.moveSlide(true);
			e.preventDefault();
		}, false);
		this.prevButton.addEventListener("click", (e)=>{ 
			this.moveSlide(false)
			e.preventDefault();
		}, false);
		this.prevButton.classList.add("disabled");

		window.addEventListener("resize", ()=>{ this.galleryResizeHandler() }, false);

		this.callerButton.addEventListener("click", (e)=>{
			this.openGallery();
			e.preventDefault();
		});
		this.closeButton.addEventListener("click", (e)=>{
			this.closeGallery();
			e.preventDefault();
		});
	}

	openGallery(){
		this.gallery.classList.add("open");
		this.nextButton.classList.add("disabled");
		this.prevButton.classList.add("disabled");
		this.currentPage = 0;
		this.gallery.setAttribute("data-page", this.currentPage);
		this.pagerCurrent.innerText = this.currentPage+1;
		this.isOpen = true;
		this.galleryResizeHandler();
		this.openAnimDelay = setTimeout(()=>{
			this.gallery.classList.add("active");
			this.nextButton.classList.remove("disabled");
		}, 1250);
	}

	closeGallery(){
		this.gallery.classList.remove("open");
		this.gallery.classList.remove("active");
		this.nextButton.classList.add("disabled");
		this.prevButton.classList.add("disabled");
		this.isOpen = false;;
		if(this.openAnimDelay){
			clearTimeout(this.openAnimDelay);
		}
	}

	galleryResizeHandler(){
		if(!this.isOpen) return false;
		this.container.style.height = window.innerHeight+'px';
		this.itemImages.forEach((item) => {
			const ratio = (item.height*1)/(item.width*1);
			const blockWidth = item.block.clientWidth;
			const blockHeight = item.block.clientHeight;
			let w,h;
			if(this.type == "collection"){
				const padding = 220;
				if(blockHeight/ratio + padding*2 <= blockWidth){
					w = blockHeight/ratio;
					h = blockHeight;
				}else{
					w = blockWidth;
					h = (blockWidth-padding*2)*ratio;
				}
			}else{
				if(blockWidth*ratio <= blockHeight){
					w = blockWidth;
					h = blockWidth*ratio;
				}else{
					w = blockHeight/ratio;
					h = blockHeight;
				}
			}
			item.imgContainer.style.width = w+"px";
			item.imgContainer.style.height = h+"px";
		}) ;
	}

	moveSlide(toNext){
		if(toNext){
			this.currentPage = Math.min(this.currentPage+1, this.maxPage);
		}else{
			this.currentPage = Math.max(this.currentPage-1, 0);
		}
		this.nextButton.classList.remove("disabled");
		this.prevButton.classList.remove("disabled");
		if(this.currentPage >= this.maxPage){ this.nextButton.classList.add("disabled"); }
		if(this.currentPage <= 0){ this.prevButton.classList.add("disabled"); }
		this.gallery.setAttribute("data-page", this.currentPage);
		this.pagerCurrent.innerText = this.currentPage+1;
	}
}

export default class Menu{
	constructor(){
		this.isToggle = false;
		this.menu = document.querySelector('.menu');
		this.toggle = document.querySelector('.menu__toggle');
		this.main = document.querySelector('.menu__main');
		this.container = document.querySelector('.menu__main__container');
		this.visual = document.querySelector('.menu__visual');
		this.visuals = document.querySelectorAll('.menu__visual__image');
		this.list = document.querySelector('.menu__body__list');
		this.items = document.querySelectorAll('.menu__body__list__item');
		this.links = document.querySelectorAll('.menuNavLink');
		this.preventAnimThrottle = null;

		this.initialize();
	}
	initialize(){
		this.toggle.addEventListener('click', (e) => {
			this.toggleMenu();
			e.preventDefault();
			return false;
		}, false);
		this.items.forEach((item) => {
			const pagename = item.getAttribute('data-page');
			item.addEventListener('mouseenter', (e) => {
				this.visual.classList.add('active');
				this.visuals.forEach((v)=>{ v.classList.remove('active'); });
				this.menu.querySelector('[data-visual="'+pagename+'"]').classList.add('active');
			}, false);
		});
		this.list.addEventListener('mouseleave', (e) => {
			this.visual.classList.remove('active');
			this.visuals.forEach((v)=>{ v.classList.remove('active'); });
		}, false);

		this.links.forEach((a) => {
			a.addEventListener('click', (e) => {
				this.toggleMenu();
			}, false);
		});

		window.addEventListener('resize', ()=>{ this.menuResizeHandler() }, false);
		this.menuResizeHandler();

		window.addEventListener('transitionOpen', () => {
			const namespace = document.querySelector('div[data-barba="container"]').getAttribute('data-barba-namespace');
			this.visual.setAttribute('data-current', namespace);
		}, false);
	}

	toggleMenu(){
		if(this.isToggle){
			this.menu.classList.remove('open');
			this.menu.classList.remove('preventAnim');
			this.main.style.height = '0px';
			this.isToggle = false;
		}else{
			this.menu.classList.add('open');
			this.main.style.height = window.innerHeight+'px';
			this.container.style.height = window.innerHeight+'px';
			this.isToggle = true;
		}
	}

	menuResizeHandler(){
		if(!this.isToggle) return false;

		this.main.style.height = window.innerHeight+'px';
		this.container.style.height = window.innerHeight+'px';

		if(this.preventAnimThrottle === null){
			this.menu.classList.add('preventAnim');
		}else{
			clearTimeout(this.preventAnimThrottle);
			this.preventAnimThrottle = null;
		}
		this.preventAnimThrottle = setTimeout(() => {
			this.menu.classList.remove('preventAnim');
			this.preventAnimThrottle = null;
		}, 100);
	}
}
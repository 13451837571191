import anime from 'animejs/lib/anime.es.js';

export default class Newsfeed{
	constructor(){
	}
	initialize(dom){
		let targetDom = document;
		if(dom){
			if(dom instanceof Element){
				targetDom = dom;
			}
		}
		const $newsfeed = targetDom.querySelectorAll('.newsfeed');
		$newsfeed.forEach(this.initNewsFeed);
	}
	initNewsFeed($newsfeed){
		const $pager = $newsfeed.querySelector('.newsfeed__pager');
		const $articles = $newsfeed.querySelectorAll('.newsfeed__article');
		const $viewport = $newsfeed.querySelector('.newsfeed__viewport');
		const blockWidth = 515;
		const size = $articles.length;
		let pages = Array(size);

		for(let i=0; i<size; i++){
			let page = document.createElement("div"); 
			page.classList.add("newsfeed__pager__page");
			page.setAttribute('data-pos', i);
			page.addEventListener("click", jump, false);
			$pager.appendChild(page);
			pages[i] = page;
		}

		function jump(e){
			let target = e.currentTarget;
			if(target.classList.contains("current")) return false;
			anime({
				targets: $viewport,
				scrollLeft: blockWidth*target.getAttribute('data-pos'),
				easing: 'easeOutExpo',
				duration: 1000
			});
			return false;
		}

		$viewport.addEventListener("scroll", onViewportScroll, false);
		function onViewportScroll(e){
			let position = Math.floor($viewport.scrollLeft/blockWidth);
			if(position < size){
				if(!pages[position].classList.contains("current")){
					for(let i=0; i<size; i++){
						pages[i].classList.remove("current")
					}
					pages[position].classList.add("current")
				}
			}
		}
		onViewportScroll();
	}
}
import barba from '@barba/core';
import Newsfeed from './modules/_newsfeed.js';
import Pillar from './modules/_pillar.js';
import Menu from './modules/_menu.js';
import Gallery from './modules/_gallery.js';
import Mailform from './modules/_mailform.js';
import Slideshow from './modules/_slideshow.js';
import Firstview from './modules/_firstview.js';
import ScrollFx from './modules/_scrollFx.js';

class App {
	constructor() {
		this.menu = new Menu();
		this.newsfeed = new Newsfeed();
		this.gallery = new Gallery();
		this.slideshow = new Slideshow();
		this.firstview = new Firstview();
		this.mailform = new Mailform();
		this.pillar = new Pillar();
		this.scrollFx = new ScrollFx();
		this.pillar.initialize();
		this.scrollFx.initialize();

		this.beforeTransitionEvent = new Event('breforeTransition');
		this.transitionOpenEvent = new Event('transitionOpen');
		this.mask = document.querySelector('.transitionMask');
		this.maskInner = document.querySelector('.transitionMask__inner');
		this.isInIndexFirstview = false;
		this.openMaskAllowFlag = null;
		this.scrollEventType = window.isTouch ? "touchmove" : "wheel";
		const mask = this.mask;
		const maskInner = this.maskInner;

		this.openTransitionMask(true);
		barba.init({
			prevent: ({ el, event, href }) => {
				if( href.match(/^#/) ) return true;
				if( document.body.classList.contains('index') ){
					if( event.type == 'click' && el.classList.contains('menuNavLink') && href.match(/^\/#/) ){
						mask.classList.remove('done');
						mask.classList.add('transition');
						maskInner.style.height = window.innerHeight+'px';
						window.addEventListener(this.scrollEventType, this.preventScroll, { passive: false });
						window.dispatchEvent(this.beforeTransitionEvent);

						setTimeout(() => {
							mask.classList.remove('transition');
							mask.classList.add('done');
							this.scrollFx.resetAppearance();
							window.dispatchEvent(this.transitionOpenEvent);
							setTimeout(() => {
								mask.classList.remove('done');
								window.removeEventListener(this.scrollEventType, this.preventScroll);
								//window.dispatchEvent(this.transitionOpenEvent);
							},750);
						}, 1000);
						return true;
					}
				}
			},
			transitions: [	
				{
					before: () => {
						mask.classList.remove('transition');
						mask.classList.remove('done');
						maskInner.style.height = window.innerHeight+'px';
						window.addEventListener(this.scrollEventType, this.preventScroll, { passive: false });
						window.dispatchEvent(this.beforeTransitionEvent);
					},
					async leave(data){
						const done = this.async();
						mask.classList.add('transition');
						setTimeout(done, 1000);
					},
					beforeEnter: (data) => {
						document.body.removeAttribute('class');
						document.body.classList.add(data.next.namespace);
						if(window.isTouch){
							document.body.classList.add('touch');
						}
						this.pillar.initialize(data.next.container);
						this.scrollFx.initialize(data.next.container);
					},
					afterEnter: (data) => {
						if(data.next.url.hash){
							const targetSection = document.querySelector('#'+data.next.url.hash);
							if(targetSection){
								const rect = targetSection.getBoundingClientRect();
								window.scrollTo(0, window.pageYOffset + rect.top);
							}
						}else{
							window.scrollTo(0, 0);
						}
						this.openTransitionMask(false, data);
						gtag('config', 'UA-150949682-1', {'page_path' : location.pathname});
					}
				}
			],
			views: [
				{
					namespace: 'index',
					beforeEnter: (data) => {
						this.newsfeed.initialize(data.next.container);
						this.gallery.initialize(data.next.container);
						this.slideshow.initialize(data.next.container);
						this.firstview.initialize(data.next.container);
					}
				},
				{
					namespace: 'contact',
					beforeEnter: (data) => {
						this.mailform.initialize(data.next.container);
					}
				}
			]
		});

		window.addEventListener('fistviewLoaded', () => {
			this.indexFirstviewLoadHandler();
		}, false);
	}
	openTransitionMask(firstLanding, transitionData){
		this.openMaskAllowFlag = false;
		this.isInIndexFirstview = false;
		if( document.body.classList.contains('index') ){
			if( !location.hash || location.hash == '#' ){
				this.isInIndexFirstview = true;
			}
		}

		let delay = 0;
		if(firstLanding == true){
			delay = 1250;
			setTimeout(() => {
				this.mask.classList.add('transition');
				this.maskInner.style.height = window.innerHeight+'px';
			},100);
		}
		
		if(this.isInIndexFirstview){
			setTimeout(() => {
				if(this.openMaskAllowFlag === true){
					this.openmask();
				}
				this.openMaskAllowFlag = true;
			}, delay);
		}else{
			setTimeout(() => {
				this.openmask();
			}, delay);
		}
	}
	indexFirstviewLoadHandler(){
		if(this.openMaskAllowFlag === true && this.isInIndexFirstview === true){
			this.openmask();
		}
		this.openMaskAllowFlag = true;
	}
	openmask(){
		this.mask.classList.remove('initial');
		this.mask.classList.remove('transition');
		this.mask.classList.add('done');
		this.openMaskAllowFlag = false;
		window.dispatchEvent(this.transitionOpenEvent);
		window.removeEventListener(this.scrollEventType, this.preventScroll);
		setTimeout(() => {
			this.mask.classList.remove('done');
			//window.dispatchEvent(this.transitionOpenEvent);
		},750);
	}
	preventScroll(e){
		e.preventDefault();
		e.stopPropagation;
	}
}

document.addEventListener('DOMContentLoaded', () => {
	if( navigator.userAgent.indexOf('iPhone') > 0        ||
		navigator.userAgent.indexOf('iPod') > 0          ||
		navigator.userAgent.indexOf('iPad') > 0          ||
		navigator.userAgent.indexOf('Windows Phone') > 0 ||
		navigator.userAgent.indexOf('BlackBerry') > 0    ||
		navigator.userAgent.indexOf('Android') > 0 ) {
		window.isTouch = true;
		document.body.classList.add('touch');
	}
	let app = new App();
}, false);
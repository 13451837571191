
export default class Mailform{
	constructor(){
	}
	initialize(dom){
		let targetDom = document;
		if(dom){
			if(dom instanceof Element){
				targetDom = dom;
			}
		}
		if(targetDom.querySelectorAll('.contact__inquiry__form').length){
			targetDom.querySelectorAll('.contact__inquiry__form__select').forEach((elm) => {
				this.pulldownChange(elm);
			});
			targetDom.querySelectorAll('form.contact__inquiry__form').forEach((elm) => {
				this.initSend(elm);
			});
		}
	}

	pulldownChange(elm){
		const element = elm;
		const label = element.querySelector('.contact__inquiry__form__select__label');
		const select = element.querySelector('select');
		select.addEventListener('change', (e)=>{ 
			label.classList.remove('initial');
			label.innerText = select.value;
		}, false);
	}
	initSend(elm){
		const sendPath = './send/';
		const form = elm;
		const button = form.querySelector('button.contact__inquiry__form__send');
		button.addEventListener('click',  (e) => {
			e.preventDefault();
			const XHR = new XMLHttpRequest();
			const formData = new FormData(form);

			form.querySelectorAll('.contact__inquiry__form__message').forEach((msg) =>{
				msg.classList.remove('show');
			});
			form.querySelectorAll('.contact__inquiry__form__message.invalid li').forEach((msg) =>{
				msg.classList.remove('show');
			});

			button.classList.add('processing');
			button.innerText = "送信中...";

			XHR.addEventListener('load', (res) => {
				if(res.target.readyState == 4 && res.target.status == 200){
					const response = res.target.response;
					if(response){
						if(response.status){
							if(response.status == 'invalid'){
								formInvalid(response.error)
							}else if(response.status == 'ok'){
								formDone();
							}else{
								formError()
							}
						}else{
							formError()
						}
					}
				}else{
					formError();
				}
			});
			XHR.addEventListener('error', (res) => {
				formError();
			});
			XHR.open('POST', sendPath, true);
			XHR.responseType = 'json';
			XHR.send(formData);
		}, false);

		function formError(){
			form.querySelector('.contact__inquiry__form__message.error').classList.add('show');
			button.classList.remove('processing');
			button.innerText = "送信する";
		}
		function formInvalid(errors){
			form.querySelector('.contact__inquiry__form__message.invalid').classList.add('show');
			for(var i=0; i<errors.length; i++){
				form.querySelector('.contact__inquiry__form__message.invalid li.'+errors[i]).classList.add('show');
			}
			button.classList.remove('processing');
			button.innerText = "送信する";
		}
		function formDone(){
			form.querySelector('.contact__inquiry__form__message.success').classList.add('show');
			button.classList.remove('processing');
			button.innerText = "送信する";
		}
	}
}


export default class Piller{
	constructor(){
		window.addEventListener("scroll", () => {
			this.onScroll();
		}, false);
	}
	initialize(dom){
		this.currentSection = "other";
		let targetDom = document;
		if(dom){
			if(dom instanceof Element){
				targetDom = dom;
			}
		}
		this.pillar_a = targetDom.querySelector('.pillar__a');
		this.sections = targetDom.querySelectorAll('[data-section]');
		this.onScroll();
	}
	onScroll(){
		let current = "other";
		this.sections.forEach((section) => {
			let bound = section.getBoundingClientRect();
			if(bound.top < window.innerHeight/2){
				current = section.getAttribute("data-section");
			}
			
		});
		if(this.currentSection != current){
			this.currentSection = current;
			this.pillar_a.setAttribute("data-current", this.currentSection);
		}
	}
}
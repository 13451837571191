import imagesLoaded from 'imagesloaded';

export default class Firstview{
	constructor(){
	}
	initialize(dom){
		let targetDom = document;
		if(dom){
			if(dom instanceof Element){
				targetDom = dom;
			}
		}
		if(targetDom.querySelectorAll('.firstview').length) {
			this.catch = targetDom.querySelector('.firstview__catch');
			this.lead = targetDom.querySelector('.firstview__lead');
			targetDom.querySelectorAll('.firstview').forEach((elm) => {
				this.initFirstview(elm);
			});
		}
	}
	initFirstview(elm){
		const firstview = elm;
		const mask = firstview.querySelector('.firstview__images__mask');
		const max = firstview.getAttribute('data-max')*1;
		const duration = 8000;
		let current = 0;
		let timeout;
		let firstFlag = true;

		setTimeout(() => {
			mask.classList.add('init');
			firstview.classList.add('firstanim');
			
			const loader = imagesLoaded(
				firstview.querySelectorAll('.firstview__images__image[data-num="0"]'),
				{ background: true }
			);
			loader.on('done', done);

			function done(){
				const event = new Event('fistviewLoaded');
				window.dispatchEvent(event);
				mask.classList.add('ready');
				firstview.classList.add('active');
				firstview.setAttribute('data-page', '0');
				timeout = setTimeout(move,duration);
				loader.off('done', done);
			}
		},250);
		
		firstview.querySelectorAll('.firstview__pager__page').forEach((page) => {
			const num = page.getAttribute('data-num')*1;
			page.addEventListener('click', (e)=>{
				current = num;
				change();
				e.preventDefault();
			}, false);
		});

		function move(){
			current++;
			if(current > max) current = 0;
			change();
		}
		function change(){
			clearTimeout(timeout);
			timeout = setTimeout(move,duration);
			firstview.setAttribute('data-page', current);
			if(firstview.classList.contains('firstanim')){
				firstview.classList.remove('firstanim');
			}
		}
	}
}


export default class ScrollFx{
	constructor(){
		this.stop = true;
		this.threshold = 150;
		window.addEventListener("scroll", () => {
			this.onScrollHandler();
		}, false);
		window.addEventListener("breforeTransition", () => {
			this.stopScrollHandler();
		}, false);
		window.addEventListener("transitionOpen", () => {
			this.startScrollHandler();
			this.onScrollHandler();
		}, false);
	}
	initialize(dom){
		this.fxBlocks = [];
		let targetDom = document;
		if(dom){
			if(dom instanceof Element){
				targetDom = dom;
			}
		}
		targetDom.querySelectorAll('.scrollFx').forEach((block) => {
			this.fxBlocks.push({
				dom: block,
				appear: false
			});
		});
		this.onScrollHandler();
	}

	onScrollHandler(){
		if(this.stop) return false;
		this.fxBlocks.forEach((block) => {
			if(block.appear) return
			const bound = block.dom.getBoundingClientRect();
			if(bound.top <= window.innerHeight - this.threshold){
				block.dom.classList.add('appear');
				block.appear = true;
			}
		});
	}
	stopScrollHandler(){
		this.stop = true;
	}
	startScrollHandler(){
		this.stop = false;
	}

	resetAppearance(){
		this.fxBlocks.forEach((block) => {
			if(block.appear){
				block.dom.classList.remove('appear');
				block.appear = false
			}
		});
	}
}
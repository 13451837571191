
export default class Slideshow{
	constructor(){
	}
	initialize(dom){
		let targetDom = document;
		if(dom){
			if(dom instanceof Element){
				targetDom = dom;
			}
		}
		const galleries = targetDom.querySelectorAll('.shopinfo__gallery')
		if(galleries.length){
			galleries.forEach((elm) => {
				this.initSlideshow(elm);
			});
		}
	}
	initSlideshow(elm){
		const slideshow = elm;
		const prev = slideshow.querySelector('.shopinfo__gallery__nav__prev');
		const next = slideshow.querySelector('.shopinfo__gallery__nav__next');
		const max = slideshow.getAttribute('data-max')*1;
		let current = 0;
		prev.classList.add("disabled");
		prev.addEventListener("click", (e)=>{
			move(false);
			e.preventDefault();
		}, false);
		next.addEventListener("click", (e)=>{
			move(true);
			e.preventDefault();
		}, false);

		function move(toNext){
			prev.classList.remove("disabled");
			next.classList.remove("disabled");
			if(toNext){
				current = Math.min(max, current+1);
			}else{
				current = Math.max(0, current-1);
			}
			if(current <= 0) prev.classList.add("disabled");
			if(current >= max) next.classList.add("disabled");
			slideshow.setAttribute("data-position", current);
		}
	}
}
